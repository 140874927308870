import { useState } from 'react';
import './App.css';

function App() {

  const audio = new Audio('preview.mp3');

  async function getMedia() {
    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });


      let context = new AudioContext();
      let mic = context.createMediaStreamSource(stream);
      let analyzer = context.createAnalyser()


      let node = context.createScriptProcessor(256, 1, 1);

      node.onaudioprocess = (ev) => {
        let buffer = (ev.inputBuffer.getChannelData(0));

        for (let i = 0; i < buffer.length; i++) {
          if (Math.abs(buffer[i]) > 0.05) {
            audio.play().then((value) => {
              setTimeout(function () {
                audio.pause()
              }, 1500);  
            });
            
          }
        }
      }

      analyzer.connect(node);

      mic.connect(analyzer);

      node.connect(context.destination);

    } catch (err) {
      /* handle the error */
    }
  }

  return (
    <div className="App">
      <button onClick={getMedia}>test</button>
    </div>


  );
}

export default App;
